.vehicle-detail {
  border-radius: 0.5rem;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  padding: 2.5rem;
  width: 100%;
  &:nth-child(2) {
    margin-top: 1rem;
  }
  .vehicle-info {
    padding: 1.25rem 1rem;
    .vehicle-stock {
      font-size: 0.75rem;
      display: flex;
    }
  }
  .vehicle-title {
    font-size: .875rem;
    font-weight: bold;
    padding: 1rem 0;
  }
  .vehicle-detail-avatar {
    width: 15rem;
    // box-shadow: 0px 12px 32px -8px rgba(42, 42, 42, 0.24);
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .vehicle-detail-list {
    flex-direction: row;
    padding-bottom: 1rem;
    .vehicle-detail-item {
      align-items: center;
      padding: 0.25rem;
      border-radius: 0.25rem;
      .vehicle-detail-item-title {
        width: 6rem;
      }
      .vehicle-detail-item-info {
        flex-grow: 1;
        text-align: right;
        font-size: 0.75rem;
      }
    }
  }
  .vehicle-photo {
    text-align: right;
    position: relative;
    .photo-link {
      font-size: .75rem !important;
      font-weight: 900 !important;
      padding: 1.875rem 0 !important;
      z-index: 10;
    }
    .vehicle-photo-list {
      display: block;
      p {
        font-size: .875rem;
        font-weight: 600;
        padding: 1rem 0;
        text-align: left;
        margin: 0;
      }
      .photos {
        width: 100%;
        display: grid;
        grid-gap: 1.25rem;
        grid-template-columns: repeat(5, 1fr);
        .photo {
          // padding: 1rem;
          text-align: left;
          display: flex;
          img {
            height: 6rem;
            object-fit: contain;
            width: 100%;
            padding: 0 0.5rem;
          }
          h6 {
            font-size: 0.75rem;
            font-weight: 600;
          }
          .after {
            margin-top: 1rem;
          }
        }
      }
      .icon-left {
        left: 0;
        margin-top: 5rem;
        position: absolute;
        img {
          cursor: pointer;
        }
      }
      .icon-right {
        right: 0;
        margin-top: 5rem;
        position: absolute;
        img {
          cursor: pointer;
        }
      }
    }
    .hide-photo {
      position: absolute;
      right: 0;
      top: 0.5rem;
      z-index: 9;
      font-size: 0.75rem;
      font-weight: 900;
    }
  }
  .vehicle-price {
    width: 10rem;
    color: #2A2A2A;
    font-size: 1.25rem;
    padding: 1.25rem 0;
    font-weight: bold;
    text-align: right;
  }
  
}

@media screen and (max-width: 768px) {
  .vehicle-detail {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    .vehicle-detail-info {
      position: relative;
      // height: 5rem;
      margin-bottom: 1rem;
      .vehicle-detail-title {
        // display: none;
        font-weight: bold;
        width: 5%;
      }
      .vehicle-detail-avatar {
        width: 30%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .vehicle-info {
        padding: 0 .5rem;
        width: 65%;
        .vehicle-info-year {
          font-size: 1rem;
        }
        h5 {
          font-size: 1rem;
          font-weight: 700;
        }
        .vehicle-stock {
          flex-direction: column;
        }
        .vehicle-stock p {
          padding: 0;
          margin: 0;
        }
      }
      .vehicle-price {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        font-size: 1rem;
        font-weight: 600;
      }
    }
    .vehicle-title {
      border-top: 1px solid #E4E4E4;
    }
    .vehicle-detail-list {
      flex-direction: column;
      padding-bottom: .5rem !important;
      .vehicle-detail-item {
        width: 100% !important;
        margin-bottom: 0.5rem !important;
      }
      .vehicle-detail-item-info {
        font-size: 0.625rem;
      }

      &.border-bottom {
        border-bottom: unset !important;
      }
    }
    .vehicle-photo {
      text-align: center;
      padding: 0 .5rem;
      .vehicle-photo-list {
        display: block;
        p {
          font-size: .875rem;
          font-weight: 600;
          padding: .5rem 0;
          text-align: left;
          margin: 0;
        }
        .photos {
          width: 100%;
          display: grid;
          grid-gap: 0.5rem;
          grid-template-columns: repeat(5, 1fr);
          h6 {
            font-size: 0.75rem;
          }
          .photo {
            img {
              height: 2.5rem;
              padding: 0;
            }
          }
        }
        .icon-left {
          display: none;
        }
        .icon-right {
          display: none;
        }
      }
      .hide-photo {
        font-size: 0.75rem;
        font-weight: 900;
      }
    }
  }
}